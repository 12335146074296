<template>
  <v-footer
    class="indigo"
    align="center"
    justify="center"
    padless>
    <v-container>
      <v-row>
        <v-col>
          <v-card
              flat
              tile
              class="indigo">
            <v-card-text>
              <v-btn
                  v-for="icon in icons"
                  :key="icon.name"
                  v-on:click="openSocialMedia(icon)"
                  class="mx-4 white--text"
                  icon>
                <v-icon>
                  {{icon.name}}
                </v-icon>
              </v-btn>
            </v-card-text>
            <v-card-text>
              Made with ❤️ by Nicolas Darr
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
  data: function (){
    return {
      icons: [
        {name: "mdi-twitter", url: "https://twitter.com/nico0o_cs"},
        {name: "mdi-instagram", url: "https://www.instagram.com/nicodarr/"},
        {name: "mdi-linkedin", url: "https://www.linkedin.com/in/nicolas-darr-3b877b102/"}
      ]
    }
  },
  methods: {
    openSocialMedia: function (icon){
      window.open(icon.url, "_blank");
    }
  }
}
</script>

<style scoped>
v-footer {
  text-align: center;
}
</style>
<template>
  <v-app>
    <v-app-bar
      app
      class="indigo"
      dark
      align="center"
      justify="center"
    >
      <v-container>
        <v-row>
          <v-col justify-center align-center>
            <h1>🔊 BPM Analyzer 🔊</h1>
          </v-col>
        </v-row>
      </v-container>

    </v-app-bar>
    <v-main>
      <BpmAnalyzer></BpmAnalyzer>
      <HowTo></HowTo>
    </v-main>
    <Footer></Footer>
  </v-app>

</template>

<script>
import BpmAnalyzer from "@/components/BpmAnalyzer";
import HowTo from "@/components/HowTo";
import Footer from "@/components/Footer";
export default {
  name: 'App',

  components: {
    Footer,
    BpmAnalyzer,
    HowTo
  },

  data: () => ({
    //
  }),
};
</script>
<style>
body {
  touch-action: manipulation;
}
</style>

<template>
<v-container>
  <v-row justify="center" align="center">
    <v-col class="col-md-3">
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            How to use it?
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            Listen to a song and push the button on every beat. Last 3 seconds are considered to calculate the BPM.
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
export default {
name: "HowTo"
}
</script>

<style scoped>

</style>
<template>
  <v-container>
    <v-row justify="center" align="center">
      <v-col justify="center" align="center"><h1>{{bpm}} BPM</h1></v-col>
    </v-row>
    <v-row>
      <v-col justify="center" align="center">
        <MemeDisplay :bpm="bpm"></MemeDisplay>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col justify="center" align="center">
        <v-btn x-large v-on:click="captureBeat">Capture beat</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MemeDisplay from "@/components/MemeDisplay";
export default {
  name: "BpmAnalyzer",
  components: {MemeDisplay},
  data: function () {
    return {
      clicks: [],
      timeFrame: 3000
    };
  },
  methods: {
    captureBeat: function () {
      const currentMs = Date.now();
      // Remove all clicks which are more than 3 seconds old
      this.clicks = this.clicks.filter((click) => currentMs - click <= this.timeFrame)
      this.clicks.push(currentMs);
    }
  },
  computed: {
    bpm: function () {
      var timings = [];
      for (var i = 0; i < this.clicks.length-1 ; i++){
        const localDelta = this.clicks[i+1] - this.clicks[i];
        timings.push(localDelta);
      }
      const averageTiming = timings.reduce((sum, timing) => (sum + timing), 0)/timings.length;
      const bpm = 60000 / averageTiming;
      return isNaN(bpm) ? 0: Math.floor(bpm);
    }
  }
}
</script>

<style scoped>
  h1 {
    font-size: xxx-large;
  }
</style>
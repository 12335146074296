<template>
  <v-img
      :src="currentImg"
      max-height="150"
      max-width="150">
  </v-img>
</template>

<script>
export default {
  name: "MemeDisplay",
  props: {
    bpm: Number
  },
  methods: {
    sampleMeme: function (memePool){
      const keys = Object.keys(memePool)
      const selectedMeme = keys[Math.floor(Math.random() * keys.length)]
      return memePool[selectedMeme];
    },
    setCurrentMemePool: function (memePool){
      this.prevMemePool = memePool;
    },
    setCurrentImageUrl: function (url) {
      this.currentImageUrl = url;
    }
  },
  computed: {
    currentImg: function () {
      var memePool;
      if (this.bpm > 195){
        memePool = this.memePool.bpm200;
      }
      else if (this.bpm > 155) {
        memePool = this.memePool.bpm160;
      }
      else if (this.bpm > 135) {
        memePool = this.memePool.bpm140;
      }
      else if (this.bpm > 105) {
        memePool = this.memePool.bpm110;
      }
      else {
        memePool = this.memePool.default;
      }
      if (this.prevMemePool !== memePool){
        this.setCurrentMemePool(memePool);
        this.setCurrentImageUrl(this.sampleMeme(memePool));
      }
      return this.currentImageUrl;
    }
  },
  data: function (){
    return {
      prevMemePool: null,
      currentImageUrl: "",
      memePool: {
        bpm200: {
          ppOverheat: require("../assets/memes/ppOverheat.gif"),
          pepegaPls: require("../assets/memes/pepegaPls.gif"),
          hyperHead: require("../assets/memes/hyperHead.gif")
        },
        bpm160: {
          pogSlide: require("../assets/memes/pogSlide.gif"),
          pepeDs: require("../assets/memes/pepeDs.gif"),
          partyKirby: require("../assets/memes/partyKirby.gif"),
          boneZone: require("../assets/memes/boneZone.gif")
        },
        bpm140: {
          partyParrot: require("../assets/memes/partyParrot.gif"),
          triDance: require("../assets/memes/triDance.gif"),
          blobDance: require("../assets/memes/blobDance.gif"),
          pepoDance: require("../assets/memes/pepoDance.gif"),
          pepeJamJam: require("../assets/memes/pepeJamJam.gif"),
          ppJedi: require("../assets/memes/ppJedi.gif"),
          pepeJammer: require("../assets/memes/pepeJammer.gif"),
          crabPls: require("../assets/memes/crabPls.gif")
        },
        bpm110: {
          pepeJam: require("../assets/memes/pepeJam.gif"),
          ppHop: require("../assets/memes/ppHop.gif"),
          forsenPls: require("../assets/memes/forsenPls.gif")
        },
        default: {
          pepeBored: require("../assets/memes/pepeBored.gif"),
        }
      }
    }
  }

}
</script>

<style scoped>

</style>